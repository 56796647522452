export enum Positioning {
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right',
    TopLeft = 'top-left',
    TopRight = 'top-right',
}

export enum ScaleMetric {
    Feet = 'ft',
    Meters = 'm',
    Kilometers = 'km',
    Miles = 'mi',
    NauticalMiles = 'nm',
}

export enum Unit {
    Imperial = 'imperial',
    Metric = 'metric',
    NauticalMiles = 'nautical',
}
