import * as React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';

interface GatedRouteProps extends RouteProps {
    value: boolean,
    redirect: string
}

// Used to redirect to login page when user is not logged in

export class GatedRoute extends Route<GatedRouteProps> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <Route render={() => {
        if (!this.props.value) {
          return <Redirect to={this.props.redirect} />;
        } else {
          return this.props.children;
        }
      }} />
    );
  }
}
