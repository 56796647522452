export enum GlobalActionTypes {
    SET_AUTHENTICATED = '@@global/SET_AUTHENTICATED',
    SHOW_SNACKBAR = '@@global/SHOW_SNACKBAR',
    HIDE_SNACKBAR = '@@global/HIDE_SNACKBAR'
}

export interface AuthenticationState {
  readonly authenticated: AuthenticationStatus
  readonly snackbar: Snackbar
}

export enum AuthenticationStatus {
  Uninitialized,
  Loading,
  Authenticated,
  Unauthenticated
}

export interface Snackbar {
  readonly type: SnackbarType
  readonly message: string
  readonly visible: boolean
}

export enum SnackbarType {
  Success = 'success',
  Warning = 'warning',
  Information = 'information',
  Error = 'error'
}

