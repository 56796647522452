/* eslint-disable quote-props */
import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {recordNotebookLaunch} from '../utils';
import {DownloadOption} from '../config';

export interface NotebookDialogProps {
  notebookUrl: string,
  downloadOptions: Array<DownloadOption>,
  onDone(): void,
}

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  launchNotebookButton: {
    width: 300,
  },
  content: {
    margonTop: 0,
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    width: 300,
    margin: '0 auto',
  },
  listItem: {
    display: 'flex',
    paddingBottom: 10,
    paddingTop: 10,
    lineHeight: '36px',
    justifyContent: 'space-between',
    '&:first-child': {
      borderBottom: '1px solid #ccc',
    },
  },
}));

export const NotebookDialog = (props: NotebookDialogProps) => {
  const [copiedOptionName, setCopiedOptionName] = React.useState('');
  const classes = useStyles();

  const onCopy = (value: string) => {
    setCopiedOptionName(value);
    setTimeout(() => setCopiedOptionName(''), 1000);
  };
  return (
    <div>
      <Dialog
        maxWidth='sm'
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={props.onDone}
      >
        <MuiDialogTitle disableTypography>
          <Typography variant="h6">Jupyter Notebook</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.onDone}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content} id="alert-dialog-description">
            <Typography variant="body1">
            You can programmatically access the mosaic data through a provided Jupyter Notebook.
            You will need to copy one of the following product URLs for use in the notebook.
            The URLs expire in 12 hours but you can come back here to generate new ones.<br/><br/>
            </Typography>
            <ul className={classes.list}>
              {props.downloadOptions.map((option) => {
                const open = copiedOptionName === option.name;
                return (<li className={classes.listItem} key={option.name}>
                  <span>{option.name}</span>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="right"
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied to Clipboard"
                  >
                    <CopyToClipboard onCopy={() => onCopy(option.name) } text={option.url}>
                      <Button variant="outlined" color="primary">Copy URL</Button>
                    </CopyToClipboard>
                  </Tooltip>
                </li>);
              })}
            </ul>
            <div className={classes.buttonWrapper}>
              <Button
                component="a"
                target="_blank"
                href={props.notebookUrl}
                className={classes.launchNotebookButton}
                variant="contained"
                onClick={() => recordNotebookLaunch()}
                color="secondary"disableElevation>
                Launch Notebook
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
