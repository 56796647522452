// adapted from https://github.com/mapbox/mapbox-gl-js/blob/061fdb514a33cf9b2b542a1c7bd433c166da917e/src/ui/control/scale_control.js#L19-L52

import {Map} from 'mapbox-gl';

import {Positioning, Unit, ScaleMetric} from './types';

type Options = {
    maxWidth?: number;
    unit?: Unit | Unit[];
    position?: Positioning;
    showIcon?: boolean;
};

export class MultiScaleControl {
    map: Map | undefined;
    _units: Unit[] = [];
    scalesContainer: HTMLElement | undefined;
    container: HTMLElement | undefined;
    metricContainer: HTMLElement | undefined;
    imperialContainer: HTMLElement | undefined;
    nauticalContainer: HTMLElement | undefined;
    options: Options = {
      maxWidth: 100,
      unit: [Unit.Metric],
      position: Positioning.BottomRight,
      showIcon: false,
    };

    constructor(options: Options) {
      this.options = Object.assign({}, this.options, options) as Options;
      this._units = (
            Array.isArray(this.options.unit) ?
                this.options.unit :
                [this.options.unit]
        ) as Unit[];
    }

    _onMove = () => {
      updateScale(
            this.map as Map,
            this.options,
            this.imperialContainer,
            this.metricContainer,
            this.nauticalContainer,
      );
    };

    onAdd(map: Map): HTMLElement {
      this.map = map;
      this.container = createDiv(
          'div',
          map.getContainer(),
          undefined,
          undefined,
      ) as HTMLElement;
      this.container.style.margin = '0 10px 10px';

      this.scalesContainer = createDiv(
          'div',
          this.container,
          'map-scales',
          undefined,
            this.options.showIcon ? '📏' : undefined,
      );

      this.scalesContainer.style.display = 'flex';
      this.scalesContainer.style.flexDirection = 'column';

      if (this._units.includes(Unit.Imperial)) {
        this.imperialContainer = createDiv(
            'div',
            this.scalesContainer,
            'imperial-scale',
            'mapboxgl-ctrl-scale maphubs-ctrl-scale',
        );
      }

      if (this._units.includes(Unit.Metric)) {
        this.metricContainer = createDiv(
            'div',
            this.scalesContainer,
            'metric-scale',
            'mapboxgl-ctrl-scale maphubs-ctrl-scale',
        );
      }

      if (this._units.includes(Unit.NauticalMiles)) {
        this.nauticalContainer = createDiv(
            'div',
            this.scalesContainer,
            'nautical-scale',
            'mapboxgl-ctrl-scale maphubs-ctrl-scale',
        );
      }

      this.map.on('move', this._onMove);
      this._onMove();

      return this.container;
    }

    onRemove() {
      this.container?.remove();
      this.map?.off('move', this._onMove);
      this.map = undefined;
    }
}

export function createDiv(
    tagName: string,
    container?: HTMLElement,
    id?: string,
    className?: string,
    icon?: string,
) {
  const el = window.document.createElement(tagName);
  const elWrapper = window.document.createElement(tagName);

  el.id = `${id}-value`;

  elWrapper.id = `${id}-wrapper`;
  elWrapper.style.display = 'flex';
  elWrapper.style.gap = '4px';

  if (icon) {
    const elIcon = window.document.createElement(tagName);
    elIcon.id = `${id}-icon`;
    elIcon.style.marginRight = '4px';
    elIcon.innerHTML = icon;

    elWrapper?.appendChild(elIcon);
  }

  if (className) el.className = className;
  elWrapper?.appendChild(el);
  container?.appendChild(elWrapper);
  return el;
}

function updateScale(
    map: Map,
    options: Options,
    metricContainer?: HTMLElement,
    imperialContainer?: HTMLElement,
    nauticalContainer?: HTMLElement,
) {
  // A horizontal scale is imagined to be present at center of the map
  // container with maximum length (Default) as 100px.
  // Using spherical law of cosines approximation, the real distance is
  // found between the two coordinates.
  const maxWidth = options?.maxWidth || 100;

  const y = map.getContainer().clientHeight / 2;
  const left = map.unproject([0, y]);
  const right = map.unproject([maxWidth, y]);
  const maxMeters = left.distanceTo(right);

  // The real distance corresponding to 100px scale length is rounded off to
  // near pretty number and the scale length for the same is found out.
  // Default unit of the scale is based on User's locale.

  if (imperialContainer) {
    const maxFeet = 3.2808 * maxMeters;
    if (maxFeet > 5280) {
      const maxMiles = maxFeet / 5280;
      setScale(
          imperialContainer,
          maxWidth,
          maxMiles,
          ScaleMetric.Miles,
          map,
      );
    } else {
      setScale(
          imperialContainer,
          maxWidth,
          maxFeet,
          ScaleMetric.Feet,
          map,
      );
    }
  }

  if (nauticalContainer) {
    const maxNauticals = maxMeters / 1852;
    setScale(
        nauticalContainer,
        maxWidth,
        maxNauticals,
        ScaleMetric.NauticalMiles,
        map,
    );
  }

  if (metricContainer) {
    if (maxMeters >= 1000) {
      setScale(
          metricContainer,
          maxWidth,
          maxMeters / 1000,
          ScaleMetric.Kilometers,
          map,
      );
    } else {
      setScale(
          metricContainer,
          maxWidth,
          maxMeters,
          ScaleMetric.Meters,
          map,
      );
    }
  }
}

function setScale(
    container: HTMLElement,
    maxWidth: number,
    maxDistance: number,
    unit: ScaleMetric,
    map: Map,
) {
  const distance = getRoundNum(maxDistance);
  const ratio = distance / maxDistance;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  if (!map.loaded() || (map.loaded() && !map.isMoving())) {
    container.style.width = `${maxWidth * ratio}px`;
    container.innerHTML = `${distance} ${unit}`;
    container.setAttribute('title', unit);
  }
}

function getRoundNum(num: number) {
  const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1);
  let d = num / pow10;

  d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

  return pow10 * d;
}
