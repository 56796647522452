export const environmentVariables = {
  accountName: getValue('REACT_APP_ACCOUNT_NAME'),
  apiGatewayUrl: getValue('REACT_APP_API_GATEWAY'),
  appBaseName: getValue('REACT_APP_BASENAME', ''),
  appCookieDomain: getValue('REACT_APP_COOKIE_DOMAIN'),
  cloudfrontDomain: getValue('REACT_APP_IDENTITY_POOL_ID'),
  cognitoIdentityPoolId: getValue('REACT_APP_IDENTITY_POOL_ID'),
  cognitoUserPoolId: getValue('REACT_APP_USER_POOL_ID'),
  cognitoUserPoolClientId: getValue('REACT_APP_USER_POOL_CLIENT_ID'),
  cognitoDomain: getValue('REACT_APP_COGNITO_DOMAIN'),
  cognitoSignInUrl: getValue('REACT_APP_SIGN_IN_URL'),
  cognitoSignOutUrl: getValue('REACT_APP_SIGN_OUT_URL'),
  googleAnalyticsTrackingId: getValue('REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID', ''),
  googleTagManagerTrackingId: getValue('REACT_APP_GOOGLE_TAG_MANAGER_TRACKING_ID', ''),
  maptiksTrackingCode: getValue('REACT_APP_MAPTIKS_TRACK_CODE'),
  maptiksIdMapBefore: getValue('REACT_APP_MAPTIKS_ID_MAP_BEFORE', ''),
  maptiksIdMapAfter: getValue('REACT_APP_MAPTIKS_ID_MAP_AFTER', ''),
  pinpointAppId: getValue('REACT_APP_PINPOINT_APP_ID'),
  publicUrl: getValue('PUBLIC_URL'),
  region: getValue('REACT_APP_REGION'),
  titilerGatewayUrl: getValue('REACT_APP_TITILER_API_GATEWAY'),
  version: getValue('REACT_APP_VERSION'),
};

function getValue(name: string, defaultValue?: string): string {
  const value = process.env[name];
  if (value == undefined) {
    if (defaultValue != undefined) {
      return defaultValue;
    }
    throw new Error(`'${name}' environment variable is required.`);
  }
  return value;
}
