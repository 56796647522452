import React from 'react';

interface ICopyrightProps {
  version: string;
  className?: string;
}

export const Copyright = ({className, version}: ICopyrightProps) => {
  const year = new Date().getFullYear();

  return (
    <div className={className}>
       © {year} EarthDaily Analytics <br/> Version {version}
    </div>
  );
};
