/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {connect} from 'react-redux';
import {Auth, API} from 'aws-amplify';
import * as turf from '@turf/turf';

import {MultiScaleControl, PositionControl, Unit, Positioning} from '../extension';

import {withStyles} from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import {DownloadDialog, NotebookDialog, Copyright} from '../components';

import {downloadFile, getS3PresignedUrl} from '../utils';
import {showSnackbar} from '../store/global/actions';
import {SnackbarType} from '../store/global/types';
import {
  DownloadOption, MapDisplayMode, MapImage, SideOptionType, BandOptionType, environmentVariables, mapConfig,
} from '../config';

import {
  recordMapDragEvent, recordMapDoubleClickEvent, recordProductDownloadEvent, recordMapSliderDragEvent, recordSignOutEvent,
  recordRightLayerChangeEvent, recordLeftLayerChangeEvent, recordMapZoomEvent, recordPageViewEvent, recordLicenseDownloadEvent,
} from '../utils';

import * as Maptiks from '../utils/Maptiks';

declare let window: Maptiks.MaptiksWindow;
window.maptiks.trackcode = environmentVariables.maptiksTrackingCode;
const {mapboxgl} = window;

// ====================================================================================================
// BASEMAP TILE SERVER URL
// ====================================================================================================

// POOR OPTIONS
// Redundant servers at "a", "b" and "c.tile.osm.org"
// const BASEMAP_TILE_SERVER_URL = 'http://a.tile.osm.org/{z}/{x}/{y}.png'            // OSM STREETS
// const BASEMAP_TILE_SERVER_URL = 'http://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}' // GOOGLE STREETS
// eslint-disable-next-line
// const BASEMAP_TILE_SERVER_URL = 'https://s2maps-tiles.eu/wmts/?layer=s2cloudless-2018&style=default&tilematrixset=WGS84&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}'

// GOOD OPTIONS
// Google satellite
// const BASEMAP_TILE_SERVER_URL = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'

// S2 2018 basemap in Web Mercator proj. Note the different order of x,y,z!
// const BASEMAP_TILE_SERVER_URL = 'https://tiles.maps.eox.at/wmts/1.0.0/s2cloudless-2018_3857/default/g/{z}/{y}/{x}.jpg'
// const BASEMAP_TILE_SERVER_URL = 'https://s2maps-tiles.eu/wmts/1.0.0/s2cloudless-2018_3857/default/g/{z}/{y}/{x}.jpg'


let initialZoomComplete = false;

type State = {
  showOverlayMenu: boolean,
  bandSelection: BandOptionType,
  mosaicsEnabled: {[key:string] : boolean},
  selLeftSideMapLayer: SideOptionType | null,
  selRightSideMapLayer: SideOptionType | null,
  idToken: string | null,
  downloadOptions: Array<DownloadOption>,
  selectedDownloadOption: DownloadOption | null,
  showingNotebook: boolean,
  mapLoading: boolean,
};

type SelectChangeHandler = React.ChangeEvent<{ name?: string; value: unknown }>;

const drawerWidth = 300;
const styles = () => ({
  app: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  mapContainer: {
    flexGrow: 1,
    minWidth: '0',
    position: 'relative',
  },
  map: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  mapLegend: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(51,51,51, 0.7)',
    color: 'white',
    height: '32px',
    fontFamily: 'Courier',
    fontSize: '0.8em',
  },
  logo: {
    padding: '20px 50px',
    width: '100%',
    paddingBottom: 10,
  },
  drawer: {
    position: 'fixed',
    width: drawerWidth + 20,
    zIndex: 100,
    height: '100%',
    transition: 'left 0.5s',
    left: 0,
  },
  drawerContent: {
    overflow: 'auto',
    borderWidth: '1px 0',
    borderColor: '#eee',
    borderStyle: 'solid',
    padding: '1em 0',
    flex: 1,
  },
  btnSignout: {
    width: '100%',
    margin: '0.5em 0',
    justifyContent: 'flex-start',
    paddingLeft: '1.25em',
  },
  btnJupiterNotebook: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textTransform: 'none',
    fontSize: '1.25em',
    lineHeight: '1.25em',
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingLeft: '1.25em',
  },
  drawClosed: {
    left: -300,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '0 0 20px rgb(0 0 0 / 30%)',
    border: 0,
    background: 'white',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  layerControls: {
    margin: '0 1.25em',
    marginBottom: '1.25em',
  },
  layerControlsTitle: {
    marginBottom: 10,
  },
  formControl: {
    width: '100%',
    marginBottom: 8,
  },
  listItem: {
    paddingLeft: 10,
  },
  drawerToggle: {
    cursor: 'pointer',
    position: 'absolute',
    top: 20,
    left: 300,
    height: 70,
    width: 25,
    boxShadow: '0px 1px 4px -2px rgb(0 0 0 / 30%)',
    background: '#e6e6e6cf',
    paddingTop: 22,
  },
  version: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 12,
    bottom: 0,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#eee',
  },
  downloadTitle: {
    paddingLeft: 20,
    paddingTop: 0,
  },
  downloadListItem: {
    margin: 0,
    padding: 0,
    paddingLeft: 20,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
    position: 'relative',
  },
  attribution: {
    padding: 2,
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'right',
    backgroundColor: 'rgba(51,51,51, 0.7)',
  },
  attributionText: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.7em',
  },
} as const);

class MosaicViewer extends React.Component<any, State> {
  mosaicBounds: {[key: string]: any}
  maps: {[key: string]: any}
  compareMap: any;
  constructor(props: any) {
    super(props);

    const mosaicsEnabled: {[key:string] : boolean} = {};
    for (const mosaic of mapConfig.imagery) {
      mosaicsEnabled[mosaic.uniqueId] = mosaic.enabledByDefault;
    }

    this.state = {
      showOverlayMenu: true,
      bandSelection: mapConfig.bandSelectionConfig.initialOption,
      mosaicsEnabled: mosaicsEnabled,
      selLeftSideMapLayer: mapConfig.layerControlConfig.leftSide.default,
      selRightSideMapLayer: mapConfig.mapDisplayMode === MapDisplayMode.DUAL ? mapConfig.layerControlConfig.rightSide!.default : null,
      idToken: null,
      downloadOptions: [],
      selectedDownloadOption: null,
      mapLoading: true,
      showingNotebook: false,
    };

    // ================================
    // Variables outside of React state
    // ================================
    this.mosaicBounds = { // Will be populated when tiles are first queried
      // 'Zambia': null,
      // 'Spain': null,
    };

    // Maps will be stored under here, keyed by map ID, once they are created
    this.maps = {};
  }

  signout() {
    recordSignOutEvent();
    Auth.signOut();
  }

  async getBounds() {
    for (const imagery of mapConfig.imagery) {
      const tasks = Object.values(imagery.layers)
          .filter((layer) => !layer.bounds)
          .map(async (layer) => {
            const response = await API.get('TiTilerApiGateway', '/cog/bounds', {
              queryStringParameters: {
                url: layer.url,
              },
            });
            layer.bounds = response.bounds;
          });
      await Promise.all(tasks);
    }
  }

  buildDownloadLinks() {
    try {
      // Build download links for each product
      for (const mosaic of mapConfig.imagery) {
        for (const layer of Object.values(mosaic.layers)) {
          getS3PresignedUrl(layer.url).then((url) => {
            this.setState({
              downloadOptions: [...this.state.downloadOptions, {
                name: layer.name,
                url: url,
                size: layer.size,
              } as DownloadOption],
            });
          });
        }
      }
    } catch (error: unknown) {
      this.props.showSnackbar(SnackbarType.Error, 'An error occurred generating download links');
    }
  }

  componentDidMount() {
    recordPageViewEvent();

    Auth.currentSession().then(async (res) => {
      this.setState({
        idToken: res.getIdToken().getJwtToken(),
      });

      try {
        await this.getBounds();
      } catch (error: unknown) {
        this.props.showSnackbar(SnackbarType.Error, 'An error occurred loading map data');
        return;
      }

      this.setState({
        mapLoading: false,
      });

      this.createMap('map1', 'map-container-before', environmentVariables.maptiksIdMapBefore);

      if (mapConfig.mapDisplayMode === MapDisplayMode.DUAL) {
        this.createMap('map2', 'map-container-after', environmentVariables.maptiksIdMapAfter);
        this.compareMap = new mapboxgl.Compare(this.maps['map1'], this.maps['map2'], '#map-container', {
          // Set this to enable comparing two maps by mouse movement:
          // mousemove: true
        });
        this.compareMap.setSlider(this.compareMap.currentPosition+150);
        this.compareMap.on('slideend', () => recordMapSliderDragEvent());
      }

      this.buildDownloadLinks();
    });
  }

  setupAnalytics(map: mapboxgl.Map) {
    const centerPosition = () => {
      const center = map.getCenter();
      return {
        lng: center.lng,
        lat: center.lat,
        zoom: map.getZoom(),
      };
    };

    // To avoid sending lots of zoom events we only record up to 1 event every 4 seconds
    let trackingRecordZoom = false;
    map.on('wheelend', () => () => {
      if (!trackingRecordZoom) {
        trackingRecordZoom = true;
        setTimeout(() => {
          recordMapZoomEvent(centerPosition());
          trackingRecordZoom = false;
        }, 4000);
      }
    });

    map.on('dragend', () => recordMapDragEvent(centerPosition()));
    map.on('dblclick', () => recordMapDoubleClickEvent(centerPosition()));
  }

  /**
   * Creates a new MapBox GL map in the provided HTTP container. The mapId is used to keep track of this
   * map and is saved to this.maps[mapId].
   *
   * @param {string} mapId: String ID to associate with this new map. Limited to one of: [ 'map1', 'map2' ]
   * @param {string} container: String ID of the HTML element to use as the container.
   * @param {string} maptikId: String ID of maptik integration.
   */
  createMap(mapId: string, container: string, maptikId: string) {
    const map = new mapboxgl.Map({
      container: container,
      style: {version: 8, sources: {}, layers: []},
      zoom: 2,
      minZoom: mapConfig.zoomConfig.minZoomLevel,
      maxZoom: mapConfig.zoomConfig.maxZoomLevel,
      attributionControl: false,
      maptiks_id: maptikId,
      transformRequest: (url) => {
        if (!url.startsWith(environmentVariables.titilerGatewayUrl)) {
          // Don't need to alter request for base map
          return {
            url: url,
          };
        }

        // Work around for handling expiring JWTs
        // We have no way of checking if id token has expired before supplying it to tile request, as we can only retrieve token asynchronously.
        // For each tile request we make, retrieve id token. If it has expired, Amplify will fetch new token.
        // This still may result in some tile requests being dropped, but panning or zooming over the same area again should result in successul tile.
        Auth.currentSession().then((res) => {
          if (res.getIdToken().getJwtToken() != this.state.idToken) {
            this.setState({
              idToken: res.getIdToken().getJwtToken(),
            });
          }
        });

        return {
          url: url,
          headers: {
            'Authorization': `Bearer ${this.state.idToken}`,
          },
        };
      },
    });

    map.addControl(new PositionControl(), Positioning.BottomRight);

    map.addControl( new MultiScaleControl({
      maxWidth: 200,
      unit: [Unit.Metric, Unit.Imperial],
    }), Positioning.BottomRight);

    map.on('load', () => {
      map.addSource('basemap-tiles', {
        'type': 'raster',
        'tiles': [
          mapConfig.basemapTileUrl,
        ],
        'tileSize': 256,
      });

      map.addLayer({
        'id': 'basemap-tiles',
        'source': 'basemap-tiles',
        'type': 'raster',
      });

      if (mapId === 'map1') {
        this.updateMap(mapId, mapConfig.imagery, this.state.bandSelection, this.state.selLeftSideMapLayer!);
      } else if (mapId === 'map2') {
        this.updateMap(mapId, mapConfig.imagery, this.state.bandSelection, this.state.selRightSideMapLayer!);
      } else {
        throw Error(`Provided mapId not supported. mapId=${mapId}.`);
      }
    });

    this.setupAnalytics(map);

    // Save reference to map
    this.maps[mapId] = map;
  }

  removeLayerAndSource(map: any, key: string) {
    if (map.getLayer(key)) {
      map.removeLayer(key);
    }
    if (map.getSource(key)) {
      map.removeSource(key);
    }
  }

  /**
   * @param {string} mapId: The map ID as a string. Can be one of [ 'map1', 'map2' ]
   * @param {Array} mosaics: List of mosaics
   * @param {string} bandSelection: The selected bands, as a concatenation of band chars, e.g. 'RGB' or 'NRG'.
   * @param {MAP_LAYERS} selMapLayer: The layer to show on the map as a string (as defined in the dropdown UI elements). Must be one of MAP_LAYERS.
   */
  updateMap(mapId: string, mosaics: MapImage[], bandSelection: BandOptionType, selMapLayer: SideOptionType) {
    const map = this.maps[mapId];
    for (const mosaic of mosaics) {
      this.removeLayerAndSource(map, mosaic.uniqueId);
      // Only redraw mosaics which are enabled
      if (selMapLayer === SideOptionType.BASEMAP_ONLY) {
        continue;
      }

      const layer = mosaic.layers[selMapLayer];
      if (!layer) {
        throw Error(`Image did not contain the expected later. image.uniqueId=${mosaic.uniqueId}, expectedLayer=${selMapLayer}.`);
      }

      const stretchParamsForBandCombo = layer.stretchParams[bandSelection];
      if (!stretchParamsForBandCombo) {
        throw Error(`No stretch parameters found for image! imageId="${mosaic.uniqueId}", bandSelection="${bandSelection}".`);
      }

      this.removeLayerAndSource(map, `${mosaic.uniqueId}_bounds`);

      // Convert band selection (e.g. "RGB") to array of band indexes
      // based on the mapping specific to this image
      // Convert array of band indexes to a string to pass
      const bandIndexesAsString = mosaic.bandIndexes[bandSelection].map((index) => `bidx=${index}`).join('&');

      const stretchParamsString = stretchParamsForBandCombo.map((s) => 'rescale=' + s.join(',')).join('&');

      const titilerGatewayUrl = environmentVariables.titilerGatewayUrl;
      const url = layer.url;
      const tileServerUrl = `${titilerGatewayUrl}/cog/tiles/{z}/{x}/{y}.png?url=${url}&${bandIndexesAsString}&${stretchParamsString}`;
      const bounds = layer.bounds;

      map.addSource(mosaic.uniqueId, {
        'type': 'raster',
        'tiles': [
          tileServerUrl,
        ],
        'tileSize': 256,
        'bounds': bounds,
      });

      map.addLayer({
        'id': mosaic.uniqueId,
        'source': mosaic.uniqueId,
        'type': 'raster',
      });

      const visibility = this.state.mosaicsEnabled[mosaic.uniqueId] ? 'visible' : 'none';
      map.setLayoutProperty(mosaic.uniqueId, 'visibility', visibility);

      if (!this.mosaicBounds[mosaic.uniqueId]) {
        this.mosaicBounds[mosaic.uniqueId] = bounds;
      }

      if ((!initialZoomComplete) && (mosaic.uniqueId === mapConfig.zoomConfig.initialZoomLocation)) {
        this.fitMapToBounds(mapId, bounds!);
        initialZoomComplete = true;
      }

      const geojson = {
        'type': 'FeatureCollection',
        'features': [turf.bboxPolygon(bounds as any)],
      };

      map.addSource(`${mosaic.uniqueId}_bounds`, {
        'type': 'geojson',
        'data': geojson,
      });

      map.addLayer({
        'id': `${mosaic.uniqueId}_bounds`,
        'type': 'line',
        'source': `${mosaic.uniqueId}_bounds`,
        'layout': {
          'line-cap': 'round',
          'line-join': 'round',
        },
        'paint': {
          'line-color': '#ccc',
          'line-width': 2,
        },
      });
    }
  }

  /**
   *
   * Zooms the map specified by mapId to just fit the given bounds.
   *
   * @param {string} mapId: The ID of the map you want to perform the zoom on. Note that in 'dual' mode, both maps will zoom as they are linked.
   * @param {Array} bounds: The bounds as an array of four values.
   */
  fitMapToBounds(mapId: string, bounds: Array<number>) {
    const map = this.maps[mapId];
    const extent = bounds;
    const llb = mapboxgl.LngLatBounds.convert(
        [
          [extent[0], extent[1]],
          [extent[2], extent[3]],
        ],
    );
    map.fitBounds(llb, {padding: {top: 50, bottom: 50, left: 350, right: 50}}); // Left padding takes into account side panal
  }

  /**
   * Handles the event when the menu button is clicked. Opens the left-hand slide-out menu.
   */
  menuButtonClick() {
    this.setState({
      showOverlayMenu: !this.state.showOverlayMenu,
    });
  }

  bandSelectionChanged({target}: SelectChangeHandler) {
    const bandSelection = target.value as BandOptionType;
    this.setState({bandSelection}, () => {
      this.updateMap('map1', mapConfig.imagery, bandSelection, this.state.selLeftSideMapLayer!);
      if (mapConfig.mapDisplayMode === MapDisplayMode.DUAL) {
        this.updateMap('map2', mapConfig.imagery, bandSelection, this.state.selRightSideMapLayer!);
      }
    });
  }

  handleMosaicEnabledChanged(event: SelectChangeHandler) {
    const target = event.target as HTMLInputElement;
    const mosaicId = target.value;
    const mosaicsEnabled = Object.assign({}, this.state.mosaicsEnabled);
    mosaicsEnabled[mosaicId] = target.checked;
    this.setState({
      mosaicsEnabled: mosaicsEnabled,
    });

    const visibility = target.checked ? 'visible' : 'none';

    if (this.maps['map1'].getLayer(mosaicId)) {
      this.maps['map1'].setLayoutProperty(mosaicId, 'visibility', visibility);
      this.maps['map1'].setLayoutProperty(`${mosaicId}_bounds`, 'visibility', visibility);
    }

    if (mapConfig.mapDisplayMode === MapDisplayMode.DUAL) {
      if (this.maps['map2'].getLayer(mosaicId)) {
        this.maps['map2'].setLayoutProperty(mosaicId, 'visibility', visibility);
        this.maps['map2'].setLayoutProperty(`${mosaicId}_bounds`, 'visibility', visibility);
      }
    }

    event.stopPropagation();
  }

  handleSelLeftSideMapLayerChanged(event: SelectChangeHandler) {
    const target = event.target as HTMLInputElement;
    const selLeftSideMapLayer = target.value as SideOptionType;
    this.setState({selLeftSideMapLayer});

    this.updateMap('map1', mapConfig.imagery, this.state.bandSelection, selLeftSideMapLayer!);
    recordLeftLayerChangeEvent(selLeftSideMapLayer);
  }

  handleSelRightSideMapLayerChanged(event: SelectChangeHandler) {
    const target = event.target as HTMLInputElement;
    const selRightSideMapLayer = target.value as SideOptionType;
    this.setState({
      selRightSideMapLayer: selRightSideMapLayer,
    });

    this.updateMap('map2', mapConfig.imagery, this.state.bandSelection, selRightSideMapLayer!);
    recordRightLayerChangeEvent(selRightSideMapLayer);
  }

  onDownloadConfirm() {
    const selectedDownloadOption = this.state.selectedDownloadOption!;
    recordProductDownloadEvent(selectedDownloadOption.name);
    downloadFile(selectedDownloadOption.url);
    this.setState({
      selectedDownloadOption: null,
    });
  }

  onDownloadLicense() {
    recordLicenseDownloadEvent();
    downloadFile(`${environmentVariables.publicUrl}/MosaicEULA.pdf`);
  }

  onNotebookDialogDone() {
    this.setState({
      showingNotebook: false,
    });
  }

  onShowNotebook() {
    this.setState({
      showingNotebook: true,
    });
  }

  onDownloadSelected(downloadOption: DownloadOption) {
    this.setState({
      selectedDownloadOption: downloadOption,
    });
  }

  onDownloadCancel() {
    this.setState({
      selectedDownloadOption: null,
    });
  }

  /**
   * Renders the app.
   *
   * @return {JSX} The HTML to render.
   */
  render() {
    const {classes} = this.props;

    // Construct the layer controls. These layer controls determine what imagery is displayed on the map.
    let layerControls = null;
    if (mapConfig.layerControlConfig.enabled) {
      layerControls = (
        <div className={classes.layerControls}>
          <div className={classes.layerControlsTitle}>Map Layers</div>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="left-side-layer-select">Left Side</InputLabel>
            <Select
              id="left-side-layer-select"
              value={this.state.selLeftSideMapLayer}
              onChange={ (e) => this.handleSelLeftSideMapLayerChanged(e) }
            >
              {mapConfig.layerControlConfig.leftSide.options.map((option) => <MenuItem value={option} key={option}>{option}</MenuItem>)}
            </Select>
          </FormControl>
          {
            mapConfig.mapDisplayMode === MapDisplayMode.DUAL && (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="right-side-layer-select">Right Side</InputLabel>
                <Select
                  id="right-side-layer-select"
                  value={this.state.selRightSideMapLayer}
                  onChange={ (e) => this.handleSelRightSideMapLayerChanged(e) }
                >
                  {mapConfig.layerControlConfig.rightSide!.options.map((option) => <MenuItem value={option} key={option}>{option}</MenuItem>)}
                </Select>
              </FormControl>
            )
          }
        </div>
      );
    }

    const drawerClosedToggle = this.state.showOverlayMenu ? '' : classes.drawClosed;

    return (
      <div className={classes.app}>
        <div className={`${classes.drawer} ${drawerClosedToggle}`}>
          <div id="sidebar" className={classes.drawerPaper}>
            <div id="sidebar-logo">
              <img alt="EarthDaily Logo" className={classes.logo} src={`${environmentVariables.publicUrl}/Logo.svg`}/>
            </div>
            <div className={classes.drawerContent}>
              {layerControls}
              <div className={classes.layerControls} style={{display: (mapConfig.bandSelectionConfig.enabled) ? 'block' : 'none'}}>
                <div className={classes.layerControlsTitle}>Band Selection</div>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.bandSelection}
                    onChange={(e) => this.bandSelectionChanged(e)}
                  >
                    {mapConfig.bandSelectionConfig.options.map(({key, label}) => <MenuItem key={key} value={key}>{label}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
              <div>
                <div className={classes.downloadTitle}>Downloads</div>
                <List>
                  {
                    this.state.downloadOptions.map((download) => {
                      return (
                        <ListItem
                          onClick={() => this.onDownloadSelected(download)}
                          className={classes.downloadListItem}
                          button
                          key={download.name}
                        >
                          <ListItemText secondary={download.size} primary={download.name} />
                        </ListItem>
                      );
                    })
                  }
                  <ListItem
                    className={classes.downloadListItem}
                    button
                    onClick={() => this.onDownloadLicense()}
                  >
                    <ListItemText secondary='345 KB' primary='End-User License Agreement' />
                  </ListItem>
                </List>
              </div>
              <div>
                <Button
                  onClick={ () => this.onShowNotebook() }
                  className={classes.btnJupiterNotebook}
                >
                  <div>
                    <div>Jupyter Notebook</div>
                    <small>Programmatically Access Mosaic</small>
                  </div>
                </Button>
              </div>
            </div>
            <div>
              <div className={classes.signoutArea}>
                <Button className={classes.btnSignout} startIcon={<ExitToAppIcon color="secondary" />} onClick={ () => this.signout() }>
                   Sign Out
                </Button>
              </div>
              <Copyright className={classes.version} version={environmentVariables.version}/>
            </div>
          </div>
          <Tooltip title={ this.state.showOverlayMenu ? 'Collapse panel' : 'Show panel'} placement="right">
            <div onClick={ () => this.menuButtonClick() } className={classes.drawerToggle}>
              { this.state.showOverlayMenu ? <ChevronLeftIcon/> : <ChevronRightIcon/> }
            </div>
          </Tooltip>
        </div>

        <div className={classes.rightColumn}>
          {this.state.mapLoading && <LinearProgress color="secondary"/>}
          <div id="map-container" className={classes.mapContainer}>
            <div id="map-container-before" className={classes.map}></div>
            {(mapConfig.mapDisplayMode === MapDisplayMode.DUAL) ?
              (<div id="map-container-after" className={classes.map}></div>) :
              null}
          </div>
          {!this.state.mapLoading && <div className={classes.attribution}>
            <a className={classes.attributionText} href={mapConfig.basemapLink}>{mapConfig.basemapAttribution}</a>
          </div>}
        </div>
        {
          this.state.selectedDownloadOption && <DownloadDialog
            title="Confirm Download"
            text={`${this.state.selectedDownloadOption.name} (${this.state.selectedDownloadOption.size})`}
            downloadUrl={this.state.selectedDownloadOption.url}
            onConfirm={ () => this.onDownloadConfirm() }
            onCancel={ () => this.onDownloadCancel() }></DownloadDialog>
        }
        {
          this.state.showingNotebook && <NotebookDialog
            downloadOptions={this.state.downloadOptions}
            notebookUrl={mapConfig.notebookUrl!}
            onDone={ () => this.onNotebookDialogDone() }
          ></NotebookDialog>
        }
      </div >
    );
  }
}

const dispatchProps = {
  showSnackbar: showSnackbar,
};

const mapStateToProps = () => ({

});

const connector = connect(
    mapStateToProps,
    dispatchProps,
);

export const MapViewer = withStyles(styles)(connector(MosaicViewer));
