import React, {useEffect} from 'react';
import {Switch} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import {Auth, Hub} from 'aws-amplify';

import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import {GatedRoute} from './components';
import {setAuthenticated, hideSnackbar} from './store/global/actions';
import {AuthenticationStatus} from './store/global/types';
import {ApplicationState} from './store';
import {Login, MapViewer, Signup} from './pages';
import {initializeGoogleAnalytics, updateTrackersAfterSignIn, recordSignInEvent, recordSignOutEvent} from './utils';

const Routes = (props: ConnectedProps<typeof connector>) => {
  useEffect(() => {
    initializeGoogleAnalytics();
    Hub.listen('auth', ({payload: {event}}) => {
      switch (event) {
        case 'signIn':
          props.setAuthenticated(AuthenticationStatus.Authenticated);
          recordSignInEvent();
          break;
        case 'signOut':
        case 'signIn_failure':
          props.setAuthenticated(AuthenticationStatus.Unauthenticated);
          recordSignOutEvent();
          break;
      }
    });

    if (props.authenticated === AuthenticationStatus.Uninitialized) {
      Auth.currentSession().then(() => {
        props.setAuthenticated(AuthenticationStatus.Authenticated);
        updateTrackersAfterSignIn();
      }).catch(() => {
        props.setAuthenticated(AuthenticationStatus.Unauthenticated);
      });
    }
  });

  if (props.authenticated === AuthenticationStatus.Uninitialized || props.authenticated === AuthenticationStatus.Loading) {
    return (
      <>
        <CssBaseline/>
        <LinearProgress color="secondary"/>
      </>
    );
  }

  const _props = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function Alert(props: any) {
    return <MuiAlert severity={_props.snackbar.type} elevation={6} variant="filled" {...props}/>;
  }

  return (
    <>
      <CssBaseline/>
      <Switch>
        <GatedRoute path='/login' value={props.authenticated === AuthenticationStatus.Unauthenticated} redirect="/">
          <Login></Login>
        </GatedRoute>
        <GatedRoute path='/signup' value={props.authenticated === AuthenticationStatus.Unauthenticated} redirect="/">
          <Signup></Signup>
        </GatedRoute>
        <GatedRoute redirect="/login" value={props.authenticated === AuthenticationStatus.Authenticated} >
          <MapViewer></MapViewer>
        </GatedRoute>
      </Switch>
      <Snackbar open={props.snackbar.visible} autoHideDuration={3000} onClose={props.hideSnackbar}>
        <Alert severity={props.snackbar.type}>
          {props.snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const dispatchProps = {
  setAuthenticated: setAuthenticated,
  hideSnackbar: hideSnackbar,
};

const mapStateToProps = ({authentication}: ApplicationState) => ({
  authenticated: authentication.authenticated,
  snackbar: authentication.snackbar,
});

const connector = connect(
    mapStateToProps,
    dispatchProps,
);

const routes = connector(Routes);

export {routes as Routes};
