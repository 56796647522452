/* eslint-disable max-len */

import {MapConfig, BandOptionType, SideOptionType, MapDisplayMode, BandOptions} from './types';

import {environmentVariables} from './environmentVariables';

const epAccountName = environmentVariables.accountName;

const stretchParams = {
  [BandOptionType.RGB]: [
    [0.0, 2000.0],
    [0.0, 2000.0],
    [0.0, 2000.0],
  ],
  [BandOptionType.NRG]: [
    [0.0, 5000.0],
    [0.0, 2500.0],
    [0.0, 2500.0],
  ],
  [BandOptionType.SNR]: [
    [0.0, 5000.0],
    [0.0, 6500.0],
    [0.0, 5000.0],
  ],
};

export const mapConfig: MapConfig = {
  // Controls whether on not the UI has a comparison slider and a left/right map. Must be one of [ 'single', 'dual' ]
  mapDisplayMode: MapDisplayMode.DUAL,
  // basemapTileUrl: 'https://s2maps-tiles.eu/wmts/1.0.0/s2cloudless-2018_3857/default/g/{z}/{y}/{x}.jpg',
  basemapTileUrl: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  basemapAttribution: 'Basemap © OpenStreetMap contributors',
  basemapLink: 'https://www.openstreetmap.org/copyright',
  // Amplify only allows generating pre-signed S3 URLs a single bucket and also require a key prefix
  notebookUrl: ' https://github.com/earthdaily/mosaics-notebook',
  imagery: [
    {
      uniqueId: 'Western_US',
      // Band numbering starts at 1 (GDAL convention)
      bandIndexes: {
        [BandOptionType.RGB]: [3, 2, 1],
        [BandOptionType.NRG]: [4, 3, 2],
        [BandOptionType.SNR]: [5, 4, 3],
      },
      layers: {
        [SideOptionType.SUMMER_2019]: {
          name: SideOptionType.SUMMER_2019,
          url: `s3://urthecast-${epAccountName}-mosaics/Western_US/EDA_Mosaic_S_L2A_EM_2019-summer-V2_subset.TIF`,
          size: '114.9 GB',
          stretchParams,
        },
        [SideOptionType.SUMMER_2020]: {
          name: SideOptionType.SUMMER_2020,
          url: `s3://urthecast-${epAccountName}-mosaics/Western_US/EDA_Mosaic_S_L2A_EM_2020-summer-V1_subset.TIF`,
          size: '115.3 GB',
          stretchParams,
        },
      },
      enabledByDefault: true,
      downloadUrl: '',
    },
  ],
  bandSelectionConfig: {
    enabled: true,
    // The bands below must exist in all imagery above
    options: Object.keys(BandOptions).map((band) => ({key: band as BandOptionType, label: BandOptions[band as BandOptionType]})),
    // Must be one of the options above
    initialOption: BandOptionType.RGB,
  },
  layerControlConfig: {
    // If disabled, no ability to change the default option will be provided to the user
    enabled: true,
    // If mapDisplayMode='single' then only have a 'leftSide'.
    // If mapDisplayMode='dual', then include both a 'leftSide' and 'rightSide'
    leftSide: {
      options: [
        SideOptionType.BASEMAP_ONLY,
        SideOptionType.SUMMER_2019,
        SideOptionType.SUMMER_2020,
      ],
      default: SideOptionType.SUMMER_2019,
    },
    rightSide: {
      options: [
        SideOptionType.BASEMAP_ONLY,
        SideOptionType.SUMMER_2019,
        SideOptionType.SUMMER_2020,
      ],
      default: SideOptionType.SUMMER_2020,
    },
  },
  zoomConfig: {
    initialZoomLocation: 'Western_US', // Must be one of the image's unique ID's
    minZoomLevel: 2, // This is enough to show the entire world
    maxZoomLevel: 17, // Limit the max. to something reasonable given the resolution of the imagery
  },
};
