import {action} from 'typesafe-actions';

import {GlobalActionTypes, AuthenticationStatus, SnackbarType} from './types';

export const setAuthenticated = (authenticated: AuthenticationStatus) => action(GlobalActionTypes.SET_AUTHENTICATED, authenticated);

export const showSnackbar = (type: SnackbarType, message: string) => action(GlobalActionTypes.SHOW_SNACKBAR, {
  type,
  message,
});

export const hideSnackbar = () => action(GlobalActionTypes.HIDE_SNACKBAR);

