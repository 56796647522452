import {API, Auth} from 'aws-amplify';

export function downloadFile(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function getS3PresignedUrl(s3Url: string): Promise<string> {
  const auth = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await API.post('ApiGateway', '/presigned_url', {
    body: {
      'url': s3Url,
    },
    headers: {Authorization: `Bearer ${auth}`},
  });
  return data['url'];
}
