import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface DownloadDialogProps {
  downloadUrl: string,
  title: string,
  text: string,
  onConfirm(url: string): void,
  onCancel(): void,
}

export const DownloadDialog = (props: DownloadDialogProps) => {
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to download <strong>{props.text}</strong> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" color="secondary" disableElevation onClick={() => props.onConfirm(props.downloadUrl) } autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
