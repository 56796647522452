import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Auth} from 'aws-amplify';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

import {recordPageViewEvent} from '../utils';
import {environmentVariables} from '../config';

// We don't automatically redirect to Cognito login page as OAuth redirect parameters only valid for a limited time period.

const useStyles = makeStyles((theme) => ({
  'root': {
    height: '100vh',
  },
  'image': {
    backgroundImage: `url(${environmentVariables.publicUrl}/LoginBackgroundWesternUS.jpeg)`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    backgroundSize: 'auto 100%',
  },
  'paper': {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  'version': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
  },
  'logo': {
    width: '60%',
    marginBottom: 15,
  },
  'submit': {
    margin: theme.spacing(2, 0, 2),
  },
  'wrapper': {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const Login = () => {
  const history = useHistory();

  useEffect(() => {
    recordPageViewEvent();
  });

  const handleSignin = (e: React.FormEvent<HTMLButtonElement>) => {
    Auth.federatedSignIn();
    e.preventDefault();
  };

  const handleSignup = (e: React.FormEvent<HTMLButtonElement>) => {
    history.push('/signup');
    e.preventDefault();
  };

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.wrapper}>
          <div className={classes.paper}>
            <section>
              <img className={classes.logo} alt="EarthDaily Logo" src={`${environmentVariables.publicUrl}/Logo.svg`}/>
              <h1>Sign up to try our EarthMosaics<sup>TM</sup> Sentinel-2 Analysis Ready Mosaic Product over Western US</h1>
            </section>
            <section>
              <p>
                <em>
                  This product is intended for demonstration purposes and can only be used for non-commercial activities.<br/>
                  Contact info@earthdaily.com for more information.
                </em>
              </p>
              <Button
                type="submit"
                disableElevation
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSignup}
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Button
                type="submit"
                disableElevation
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSignin}
                className={classes.submit}
              >
                Continue To Sign In
              </Button>
            </section>
          </div>
          <div className={classes.version}>Version {environmentVariables.version}</div>
        </div>
      </Grid>
    </Grid>
  );
};
