import Amplify, {Auth, Analytics} from 'aws-amplify';

import {environmentVariables} from '../config';

export const configure = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: environmentVariables.cognitoIdentityPoolId,

      // REQUIRED - Amazon Cognito Region
      region: environmentVariables.region,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: environmentVariables.region,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environmentVariables.cognitoUserPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: environmentVariables.cognitoUserPoolClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        domain: environmentVariables.appCookieDomain,
        path: '/',
        expires: 365,
        sameSite: 'lax',
        secure: false,
      },
      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: environmentVariables.cognitoDomain,
        scope: ['phone', 'email', 'profile', 'openid'],
        redirectSignIn: environmentVariables.cognitoSignInUrl,
        redirectSignOut: environmentVariables.cognitoSignOutUrl,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: 'TiTilerApiGateway',
          endpoint: environmentVariables.titilerGatewayUrl,
          custom_header: async () => {
            // Add JWT to all API calls
            return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`};
          },
        },
        {
          name: 'ApiGateway',
          endpoint: environmentVariables.apiGatewayUrl,
        },
      ],
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: environmentVariables.pinpointAppId,
        region: environmentVariables.region,
      },
    },
  });
};

Analytics.autoTrack('event', {
  enable: true,
});

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'PageView',
  type: 'SPA',
});

Analytics.autoTrack('session', {
  enable: true,
});
