import {combineReducers} from 'redux';
import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';

import {AuthenticationState} from './global/types';
import {authenticationReducer} from './global/reducer';

// The top-level state object
export interface ApplicationState {
  router: RouterState
  authentication: AuthenticationState
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authentication: authenticationReducer,
  });

