// adapted from https://github.com/mapbox/mapbox-gl-js/blob/061fdb514a33cf9b2b542a1c7bd433c166da917e/src/ui/control/scale_control.js#L19-L52

import {Map, LngLat} from 'mapbox-gl';

import {Positioning} from './types';

type Options = {
    position?: Positioning;
    showZoom?: boolean;
    showPosition?: boolean;
    showIcon?: boolean;
};

export class PositionControl {
    map: Map | undefined;
    container: HTMLElement | undefined;
    positionContainer: HTMLElement | undefined;
    zoomContainer: HTMLElement | undefined;
    options: Options = {
      position: Positioning.BottomRight,
      showPosition: true,
      showZoom: true,
      showIcon: false,
    };

    constructor(options = {} as Options) {
      this.options = Object.assign({}, this.options, options) as Options;
    }

    _onMouseMove = (coords: { lngLat: LngLat }) => {
      if (coords?.lngLat && this.positionContainer) {
        updatePosition(
                this.positionContainer as HTMLElement,
                coords.lngLat,
        );
      }
    };

    onAdd(map: Map): HTMLElement {
      this.map = map;
      this.container = createDiv(
          'div',
          map.getContainer(),
          undefined,
          undefined,
      ) as HTMLElement;
      this.container.style.margin = '0 10px 10px';
      this.container.style.padding = '4px 10px';
      this.container.style.color = '#444';
      this.container.style.fontSize = '0.8em';
      this.container.style.fontFamily = 'Courier';
      this.container.style.borderRadius = '2px';
      this.container.style.lineHeight = '1.5';
      this.container.style.backgroundColor = 'rgba(255,255,255, 0.7)';

      if (this.map && this.options.showZoom) {
        this.zoomContainer = createDiv(
            'div',
            this.container,
            'map-zoom',
            undefined,
            this.options.showIcon ? '🔎' : undefined,
        );
        map.on('zoomend', () => {
          if (this.zoomContainer) {
            this.zoomContainer.innerHTML = `zoom: ${map
                .getZoom()
                .toFixed(2)
                .toString()}`;
          }
        });
      }

      if (this.options.showPosition) {
        this.positionContainer = createDiv(
            'div',
            this.container,
            'map-position',
            undefined,
            this.options.showIcon ? '🧭' : undefined,
        );
      }

      this.map.on('mousemove', this._onMouseMove);

      return this.container;
    }

    onRemove() {
      this.container?.remove();
      this.map?.off('mousemove', this._onMouseMove);
      this.map = undefined;
    }
}

function createDiv(
    tagName: string,
    container?: HTMLElement,
    id?: string,
    className?: string,
    icon?: string,
) {
  const el = window.document.createElement(tagName);
  const elWrapper = window.document.createElement(tagName);

  el.id = `${id}-value`;

  if (icon) {
    const elIcon = window.document.createElement(tagName);
    elIcon.id = `${id}-icon`;
    elIcon.style.marginRight = '4px';
    elIcon.innerHTML = icon;

    elWrapper?.appendChild(elIcon);
  }

  if (className) el.className = className;
  elWrapper?.appendChild(el);
  container?.appendChild(elWrapper);
  return el;
}

function updatePosition(container: HTMLElement, lngLat: LngLat) {
  const lat = lngLat.lat.toFixed(6);
  const lng = lngLat.lng.toFixed(6);
  container.innerHTML = `lat: ${lat}, lng: ${lng}`;
}
