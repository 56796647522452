/* eslint-disable max-len */
import ReactGA from 'react-ga';
import {Auth, Analytics} from 'aws-amplify';

import {environmentVariables} from '../config';

// Tracking id must the universal type - starts with 'UA-' not 'G-'
const gaTrackingId = environmentVariables.googleAnalyticsTrackingId;
const gaEnabled = gaTrackingId ? true : false;
const gaDisabledMessage = 'No Google Analytics tracking id present. Feature disabled.';

export function initializeGoogleAnalytics() {
  if (gaEnabled) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactGA.initialize(gaTrackingId!, {
      titleCase: false,
    });
  } else {
    console.log(gaDisabledMessage);
  }
}

export async function updateTrackersAfterSignIn() {
  try {
    // Get user details
    Auth.currentSession().then(async (session) => {
      const attributes = session.getIdToken().payload;
      // Update AWS PinPoint with user info
      await Analytics.updateEndpoint({
        address: attributes.email,
        userId: attributes.email,
        userAttributes: {
          email: [attributes.email],
          family_name: [attributes.family_name],
          given_name: [attributes.given_name],
        },
      });
      if (gaEnabled) {
        // Update Google Analytics with user info
        ReactGA.set({userId: attributes.email});
      } else {
        console.log(gaDisabledMessage);
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function recordPageViewEvent() {
  // Page view for AWS automatically tracked
  if (gaEnabled) {
    ReactGA.pageview(window.location.pathname);
  }
}

// Using single Google Analytics category for all events currently
const category = 'Navigation';

// Same event names are used in Google Analytics and AWS Pinpoint
enum Events {
  SignIn = 'SignIn',
  SignOut = 'SignOut',
  ProductDownload = 'ProductDownload',
  LicenseDownload = 'LicenseDownload',
  SignUpAttempt = 'SignUpAttempt',
  SignUp = 'SignUp',
  RightLayerChange = 'RightLayerChange',
  LeftLayerChange = 'LeftLayerChange',
  MapDoubleClick = 'MapDoubleClick',
  MapDrag = 'MapDrag',
  MapSliderDrag = 'MapSliderDrag',
  MapZoom = 'MapZoom',
  NotebookLaunch = 'NotebookLaunch',
}

interface EventData {
  // Pinpoint metrics can only be a dict of numbers
  ppMetrics?: unknown,
  // Pinpoint attributes can only be a dict of string
  ppAttributes? : unknown,
  // Google Analytics label can only be a string
  gaLabel? :string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recordEvent(event: Events, data?: EventData): void {
  try {
    // Record a Pinpoint event
    Analytics.record({
      name: event,
      metrics: data?.ppMetrics,
      attributes: data?.ppAttributes,
    });
  } catch (error: unknown) {
    console.log('Error saving Pinpoint event');
  }

  if (gaEnabled) {
    try {
      // Recod a Google Analytics event
      ReactGA.event({
        category,
        action: event,
        label: data?.gaLabel,
      });
    } catch (error: unknown) {
      console.log('Error saving Google Analytics event');
    }
  }
}


export const recordSignInEvent = () => recordEvent(Events.SignIn);
export const recordSignOutEvent = () => recordEvent(Events.SignOut);
export const recordNotebookLaunch = () => recordEvent(Events.NotebookLaunch);

export const recordSignUpEvent = (email: string) => recordEvent(Events.SignUp, {
  ppAttributes: {email: email},
  gaLabel: email,
});

export const recordSignUpAttemptEvent = (email: string) => recordEvent(Events.SignUpAttempt, {
  ppAttributes: {email: email},
  gaLabel: email,
});

export const recordProductDownloadEvent = (product: string) => recordEvent(Events.ProductDownload, {
  ppAttributes: {product: product},
  gaLabel: product,
});

export const recordLicenseDownloadEvent = () => recordEvent(Events.LicenseDownload);

export const recordRightLayerChangeEvent = (layer: string) => recordEvent(Events.RightLayerChange, {
  ppAttributes: {layer: layer},
  gaLabel: layer,
});
export const recordLeftLayerChangeEvent = (layer: string) => recordEvent(Events.LeftLayerChange, {
  ppAttributes: {layer: layer},
  gaLabel: layer,
});

// Map events

export interface Position {
  lng: number,
  lat: number,
  zoom: number
}

export const recordMapDoubleClickEvent = (position: Position) => recordEvent(Events.MapDoubleClick, {
  ppMetrics: position,
  gaLabel: JSON.stringify(position),
});

export const recordMapDragEvent = (position: Position) => recordEvent(Events.MapDrag, {
  ppMetrics: position,
  gaLabel: JSON.stringify(position),
});

export const recordMapSliderDragEvent = () => recordEvent(Events.MapSliderDrag);

export const recordMapZoomEvent = (position: Position) => recordEvent(Events.MapZoom, {
  ppMetrics: position,
  gaLabel: JSON.stringify(position),
});
