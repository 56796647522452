import {createMuiTheme} from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#192432',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00B2F4',
      contrastText: '#fff',
    },
    text: {
      primary: '#192432',
    },
  },
  typography: {
    fontFamily: [
      'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif',
    ].join(','),
  },
});
