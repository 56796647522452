import {Reducer} from 'redux';
import {AuthenticationState, GlobalActionTypes, AuthenticationStatus, SnackbarType} from './types';

export const initialState: AuthenticationState = {
  authenticated: AuthenticationStatus.Uninitialized,
  snackbar: {
    visible: false,
    message: '',
    type: SnackbarType.Success,
  },
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.SET_AUTHENTICATED: {
      return {...state, authenticated: action.payload};
    }
    case GlobalActionTypes.SHOW_SNACKBAR: {
      return {...state, snackbar: {
        visible: true,
        message: action.payload.message,
        type: action.payload.type,
      }};
    }
    case GlobalActionTypes.HIDE_SNACKBAR: {
      return {...state, snackbar: {
        visible: false,
        message: '',
        type: SnackbarType.Success,
      }};
    }
    default: {
      return state;
    }
  }
};

export {reducer as authenticationReducer};
