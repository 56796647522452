// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useEffect} from 'react';
import {API} from 'aws-amplify';
import * as yup from 'yup';
import phone from 'phone';

import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import {recordPageViewEvent, recordSignUpAttemptEvent, recordSignUpEvent} from '../utils';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginLarge: {
    margin: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const industries = [
  'Academic',
  'Agriculture',
  'Defence',
  'Environment',
  'Insurance',
  'Forestry',
  'Mining',
  'Other',
];

export const Signup = () => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    organization: '',
    usecase: '',
    industry: '',
    loading: false,
  });
  const [error, setError] = React.useState('');
  const [registrationCompleted, setRegistrationCompleted] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<any>) => {
    const target = event.target as HTMLInputElement;
    let value = target.value;

    if (prop === 'phone_number') {
      value = phone(value)[0] || value;
    }

    setValues({...values, [prop]: value});
  };

  useEffect(() => {
    recordPageViewEvent();
  });

  const handleSignup = (event: React.MouseEvent) => {
    validateForm().then(async () => {
      setValues({...values, ['loading']: true});
      recordSignUpAttemptEvent(values.email);
      await API.post('ApiGateway', '/users', {
        body: {
          'email': values.email,
          'given_name': values.first_name,
          'family_name': values.last_name,
          'phone_number': values.phone_number, // E.164 number convention
          'custom:organization': values.organization,
          'custom:mosaic_use': values.usecase,
          'custom:industry': values.industry,
        },
      }).catch((error) => {
        throw error.response?.data?.message ?? 'An error occurred signing up';
      });
    }).then(() => {
      setRegistrationCompleted(true);
      recordSignUpEvent(values.email);
    }).catch((error) => {
      console.log('error signing up:', error);
      setError(error);
    }).finally(() => {
      setValues({...values, ['loading']: false});
    });
    event.preventDefault();
  };

  const validateForm = () => {
    const schema = yup.object().shape({
      'first_name': yup.string().required().min(2),
      'last_name': yup.string().required().min(2),
      'email': yup.string().required().email(),
      'phone_number': yup.string().required().min(5).max(16).matches(/(\+)(\d+)/, 'Phone number is invalid'),
      'organization': yup.string().required().min(3).max(50),
      'usecase': yup.string().required().min(6),
      'industry': yup.string().required(),
    });
    console.log(values);
    return schema.validate(values)
        .catch((err) => {
          console.log(err);
          throw err.errors.join('. ').replace('_', ' ');
        });
  };

  const registrationForm = () => {
    return (
      <div>
        <FormControl fullWidth className={classes.margin} required>
          <InputLabel htmlFor="firstName">First name</InputLabel>
          <Input
            id="firstName"
            value={values.first_name}
            required
            onChange={handleChange('first_name')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin} required>
          <InputLabel htmlFor="lastName">Last name</InputLabel>
          <Input
            id="lastName"
            value={values.last_name}
            required
            onChange={handleChange('last_name')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin} required>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            value={values.email}
            required
            onChange={handleChange('email')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin} required>
          <InputLabel htmlFor="phone">Phone</InputLabel>
          <Input
            id="phone"
            value={values.phone_number}
            required
            onChange={handleChange('phone_number')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin} required>
          <InputLabel htmlFor="organization">Organization</InputLabel>
          <Input
            id="organization"
            value={values.organization}
            required
            onChange={handleChange('organization')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <InputLabel htmlFor="industry" required>Industry</InputLabel>
          <Select
            id="industry"
            value={values.industry}
            required
            onChange={handleChange('industry')}
          >
            <MenuItem key="select" value="">Please Select</MenuItem>
            {industries.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <InputLabel htmlFor="usecase" required>What do you intend to use the mosaics for?</InputLabel>
          <Input
            id="usecase"
            value={values.usecase}
            multiline
            rows="2"
            required
            onChange={handleChange('usecase')}
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          {error !== '' && <Alert severity="error" className={classes.margin}>{error}</Alert>}
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              disableElevation
              fullWidth
              variant="contained"
              color="secondary"
              disabled={values.loading}
              onClick={handleSignup}
            >
            Register
            </Button>
            {values.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </FormControl>
      </div>
    );
  };

  const confirmMessage = () => {
    return (
      <div>
        <Typography variant="body1" gutterBottom align="center">
          Thank you for signing up. An email will be sent to you when your account is ready.
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <img
          src="https://earthdailycdn-1235e.kxcdn.com/wp-content/uploads/2020/04/e9-EarthDaily-Logo-Color.svg"
          className={classes.marginLarge}>
        </img>
        <Typography variant="h3" gutterBottom align="center">
          Mosaics Preview Sign up
        </Typography>
        {registrationCompleted? confirmMessage() : registrationForm() }
      </Container>
    </div>
  );
};
