import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history';
import 'typeface-ibm-plex-sans';
import TagManager from 'react-gtm-module';

import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {configureStore} from './configureStore';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from './theme';
import {environmentVariables} from './config';

TagManager.initialize({
  gtmId: environmentVariables.googleTagManagerTrackingId,
});

const history = createBrowserHistory({basename: environmentVariables.appBaseName});

const initialState = window.INITIAL_REDUX_STATE;
export const store = configureStore(history, initialState);

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App store={store} history={history} />
    </ThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
