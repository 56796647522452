export enum MapDisplayMode {
  SINGLE,
  DUAL
}

export interface MapState {
  showOverlayMenu: boolean,
  bandSelection: BandOptionType,
  mouseLatLng: {
    lat: number;
    lng: number;
  },
  mosaicsEnabled: Record<string, boolean>;
  selLeftSideMapLayer: SideOptionType;
  selRightSideMapLayer: SideOptionType;
  currTileZoomLevel: number;
  mapConfig: MapConfig;
}

export interface DownloadOption {
  url: string;
  name: string;
  size?: string;
}

export interface MapConfig {
  notebookUrl?: string;
  mapDisplayMode: MapDisplayMode;
  basemapTileUrl: string;
  basemapAttribution: string;
  basemapLink: string;
  imagery: MapImage[];
  bandSelectionConfig: BandSelectionConfig;
  layerControlConfig: LayerControlConfig;
  zoomConfig: ZoomConfig;
}

export interface MapImage {
  uniqueId: string;
  bandIndexes: Record<BandOptionType, number[]>;
  layers: Record<string, MapLayer>;
  enabledByDefault: boolean;
  downloadUrl: string;
}

export interface ZoomConfig {
  initialZoomLocation: string;
  minZoomLevel: number;
  maxZoomLevel: number;
}

export enum SideOptionType {
  BASEMAP_ONLY = 'Basemap Only',
  SUMMER_2019 = '2019 Summer',
  SUMMER_2020 = '2020 Summer'
}

export interface Side {
  options: SideOptionType[];
  default: SideOptionType;
}

export interface LayerControlConfig {
  enabled: boolean;
  leftSide: Side;
  rightSide?: Side;
}

export interface MapLayer {
  url: string;
  bounds?: number[];
  name: string;
  size?: string;
  stretchParams: MapLayerStretchParameters;
}

export interface MapLayerStretchParameters {
  RGB?: number[][];
  NRG?: number[][];
  SNR?: number[][];
}

export interface BandSelectionConfig {
  enabled: boolean;
  options: { key: BandOptionType; label: string }[];
  initialOption: BandOptionType;
}

export enum BandOptionType {
  RGB = 'RGB',
  NRG = 'NRG',
  SNR = 'SNR',
}

export const BandOptions = {
  [BandOptionType.RGB]: 'True Colour (R, G, B)',
  [BandOptionType.NRG]: 'False Infrared Colour (NIR, R, G)',
  [BandOptionType.SNR]: 'Shortwave Infrared Colour (SWIR, NIR, R)',
};
